.WhatsappButton {
  position: fixed;
  bottom: 0px;
  right: 0px;
  z-index: 1;
}

.WhatsappButton img {
  width: 60px;
}

.WhatsappButton-message {
  position: fixed;
  bottom: 20px;
  right: 80px;
  background-color: #fff;
  color: #000;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 2;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: calc(100% - 160px);
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.WhatsappButton-overlay {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  background-color: transparent;
  z-index: 2;
  cursor: pointer;
}

/* Mostramos el mensaje al pasar el cursor sobre el botón */
.WhatsappButton:hover .WhatsappButton-message {
  display: block;
}
@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}