

.swiper {
    
    max-width: 100%;
    width: 100%;
    height: 60vh;
  }
  
.swiper-slide {
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    width:fit-content !important;
    height: auto;
}

.swiper-slide img {
    display: block;
    max-width: 100%;
    width: "auto";
    height: 100%;
    object-fit: cover;
}
.swiper-slide a {
    width:fit-content;
    max-height: 100%;
    max-width: 100%;
    width: "auto";
    height: 100%;
    object-fit: cover;
}

.swiper-wrapper {
    -webkit-transition-timing-function:linear!important; 
    -o-transition-timing-function:linear!important;
    transition-timing-function:linear!important; 
  }
  