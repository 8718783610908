.slick-track {
  display: flex;
}
.slick-track .slick-slide {
  display: flex;
  height: auto;
  align-items: center;
  justify-content: center;
}

.slick-prev:before, .slick-next:before {
    color: black;
  }
  
.slick-prev {
    left: 20px;
    z-index: 1;
    height: auto;
    align-items: center;
    text-align: center;
    height: 100%;
  }
  
.slick-next {
    right: 20px;
    z-index: 1;
    align-items: center;
    text-align: center;
    height: 100%;
  }
  .slick-slide img {
    max-width: 100%;
    width: 100%;
    height: auto;
    padding-inline: 5px;
  }
  .slick-slide .MuiPaper-root {
    height: auto;
    justify-content: "center";
    display: "flex";
    align-items:"center";
    align-content:'center';
  }
  .slick-slide {
    display: flex;
    justify-content: center!important;
  }
  @media screen and (max-width: 900px) {
    .slick-slide img {
      max-width: 100%;
      height: auto;
      max-height: 100vh;
      width: 100vw;
    }
  }  
  @media screen and (max-width: 600px) {
    .slick-slide img {
      max-width: 100%;
      height: auto;
      max-height: 100vh;
      width: 100vw;
    }
  }
  



  menuactive:after {
    content: "";
    display: block;
    border-bottom: 2px solid blue;
    margin-top: 5px;
  }

.SliderNosotros .slide{
  

  width: 100%;
  max-width: 900px;
  display: flex !important;
  justify-content: center;
  flex:1;
  flex-direction: column;

}

.SliderNosotros .slick-slide{
  max-height: none;
  position: relative;
  display: flex;
  justify-content: center;
  transition: transform 1s ease-in-out,opacity 1s ease-in-out;
  
}
.SliderNosotros .slick-slide.slick-center{
  opacity: 1;
}
.SliderNosotros .slick-slide:not(.slick-center){
  opacity: 0;
  transform: scale(0.8);
  transition-delay: 0s;
}



.SliderNosotros img{
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  object-fit: cover;
  height: 400px;
  width: auto;
  max-width: 100%;
  max-height: 400px;
  /*border-radius: 50%;
  border: 2px solid;*/
  align-self: center;

}
@media screen and (max-width: 900px) {
  .SliderNosotros img {
  height: auto;
  width: 100%;

  }
}  


.SliderNosotros .name{
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
}
.SliderNosotros .cargo{
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 10px;
}
.SliderNosotros .descripcion{

  margin-top: 0;
  text-align: justify;
  overflow: hidden;
   display: -webkit-box;
}